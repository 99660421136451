import React, { useState, useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import loadScript from "../components/loadScript"
import { injectIntl,IntlContextConsumer,Link } from "gatsby-plugin-intl";
import LanguageSwitcher from '../components/LanguageSwitcher';

export function SellingYourProperty({data,intl}){
    const [reCaptcha, setReCaptcha] = useState(false);
    const [checked, setCheck] = useState(false);
    
    const reCaptchaCallback = (response) => {
        response !== null ? setReCaptcha(true) : setReCaptcha(false);
    }

    function loadBundleScript() {
		loadScript(document.location.origin + '/theme/output/bundle.js')
		.then((script) => {
		    setupContact();
		})
		.catch((err) => {
				console.error(err.message);
		});
    }

    useEffect(() => {
        if (!window.$) {
            loadBundleScript();
        }
        else{
            setupContact();
        }
    })

    function setupContact(){   
        /* attach a submit handler to the form */;    
        window.$("#sell-contact-form").unbind("submit").bind("submit", function(event) {  
            window.$("#send-email .fa").addClass("rotating");

            /* stop form from submitting normally */
            event.preventDefault();
            event.stopImmediatePropagation();
      
            /* get the action attribute from the <form action=""> element */
            var $form = window.$(this),
                url = $form.attr( 'action' );

             var posting = { 
                name: window.$('#name-input').val(), 
                email: window.$('#email-input').val(),
                telephone: window.$("#telephone-input").val(),
                location: window.$('#location-input').val(),
                zone: window.$('#zone-input').val(),
                language: window.$(".select-language-contact-form").val(),
                contactMethod: window.$("#select-contact-method").val()
             };

             var alwaysCallback = function(){
                setTimeout(function(){
                        window.$("#send-email .fa").removeClass("rotating");
                },1500);
             };

            window.$.ajax({
                url:url,
                type:"POST",
                data: JSON.stringify(posting),
                contentType:"application/json; charset=utf-8"
            })
            .done(function( data ) {
                    setTimeout(function(){            
                        window.$("#send-email").addClass("correct");
                        window.$("#send-email").attr("disable", "true").css("opacity","0.5");
                        window.$(".correct-email-sentence").css("visibility","visible");
                    },1500);
                    alwaysCallback();
            })
            .fail(function() {
                window.$("#send-email").addClass("error");
                console.error("Error in contact form");
                window.$(".error-email-sentence").css("visibility","visible");
                alwaysCallback();
            });

            window.$("#sell-contact-form").unbind("submit");
            return false;
          });
    }


    function onChange(){
        setCheck(!checked);
    }

    return(
        <Layout>
            <SEO title={intl.formatMessage({ id: 'Menu-sellYourProperty' })} />
            

                    <section>
                        {/*<BackgroundImage Tag="section"
                            fluid={data.placeholderImage.childImageSharp.fluid}
                            className="page-top-section"
                        >
                        </BackgroundImage>*/}
                        <div className="mainTitle">
                            <h2>{intl.formatMessage({ id:'Selling-your-property'})}</h2>
                        </div>

                        <div className="site-breadcrumb">
                            <div className="container">		
                            </div>
                        </div>

                        <section className="page-section blog-page" id="sell-your-property">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Img alt="" fluid={data.contactImage.childImageSharp.fluid} />
                                        <h4 className="green_title">{intl.formatMessage({ id: 'Sell-property-sell_how_works'})}</h4>
                                        <ul className="generic">
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_0'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_1'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_2'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_3'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_4'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-how_works_5'})}
                                            </li>
                                        </ul>
                                        <h4 className="green_title">{intl.formatMessage({ id: 'Sell-property-extras'})}</h4>
                                        <ul>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-extras_0'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-extras_1'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-extras_2'})}
                                            </li>
                                            <li>
                                                <i className="fa fa-check-circle-o green_icon"></i>
                                                {intl.formatMessage({ id: 'Sell-property-extras_3'})}
                                            </li>
                                        </ul>
                                        <p 
                                            className="generic"
                                            style={{
                                                marginTop: "15px"
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'Sell-property-extras_extended0'})}
                                        </p>
                                        <p className="generic">{intl.formatMessage({ id: 'Sell-property-extras_extended1'})}</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact-right">
                                            <div className="section-title">
                                                <h3 className="generic">{intl.formatMessage({ id:'Sell-property-title'})}</h3>
                                                <p className="generic">{intl.formatMessage({ id:'Sell-property-subtitle'})}</p>
                                            </div>
                                            <form className="contact-form" action="/SellProperty" id="sell-contact-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input required type="text" placeholder={intl.formatMessage({ id:'Form-your-name'})} id="name-input"></input>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input required type="email" placeholder={intl.formatMessage({ id:'Form-your-email'})}  id="email-input"></input>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input type="text" placeholder={intl.formatMessage({ id:'Form-your-phone'})}  id="telephone-input"></input>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input required type="text" placeholder={intl.formatMessage({ id:'Form-property-location'})} maxLength="100"  id="location-input"></input>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input required type="text" placeholder={intl.formatMessage({ id:'Form-zone'})} maxLength="100"   id="zone-input"></input>
                                                    </div>
                                                    <div className="col-md-12">
                                                    <LanguageSwitcher contactForm="true"/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <p>{intl.formatMessage({ id:'Form-how-contact-you'})}</p>
                                                        <select id="select-contact-method" defaultValue="Email">
                                                            <option value="Email">{intl.formatMessage({ id:'Form-email'})}</option>
                                                            <option value="Telephone">{intl.formatMessage({ id:'Form-phone'})}</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-check checkbox_privacy">
                                                            <input  checked={checked} onChange={onChange} className="form-check-input" type="checkbox"></input>
                                                            <label className="form-check-label">
                                                                {intl.formatMessage({ id:'IAcceptYour'})}
                                                                <Link to="/privacy-policy/"  target="_blank"> 
                                                                &nbsp;{intl.formatMessage({ id:'PrivacyPolicy'})}
                                                                </Link>
                                                            </label>
                                                        </div>

                                                        <IntlContextConsumer>
                                                            {({ languages, language: currentLocale }) => (
                                                                <ReCAPTCHA
                                                                    className="g-recaptcha"
                                                                    sitekey="6LdAYMMUAAAAAOH-xJYMYhwfGzX61e3Z6svKAY5o"
                                                                    size="compact"
                                                                    hl={currentLocale}
                                                                    onChange={reCaptchaCallback}
                                                                />
                                                            )}
                                                        </IntlContextConsumer>

                                                        <button disabled={(!checked || !reCaptcha)} className="site-btn" type="submit" id="send-email"><span id="summit">{intl.formatMessage({ id:'Form-send'})}</span><span><i className="fa fa-calendar-check-o"></i></span></button>
                                                        <p className="correct-email-sentence">{intl.formatMessage({ id:'Form-sent'})}</p>
                                                        <p className="error-email-sentence">{intl.formatMessage({ id:'Form-sent-error'})}</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>

        </Layout>
    )
}

export default injectIntl(SellingYourProperty)

export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            }
        }
    }
    contactImage: file(relativePath: { eq: "selling-property.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`
